import packageInfo from '../../package.json';

export const environment = {
  production: true,
  apiUrl: 'https://adewb.izs.it/api-adewb/api',
  appVersion:  packageInfo.version,
  mailSupport: 'adewb.support@izs.it',
  siteKey: '6LdIV-4eAAAAAHxObrq09GGZ7p3tBZHE3k0t5cjp',
  appName: 'ADEWB - Mapping Tool'
};
